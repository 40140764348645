import React, { Component } from 'react';
import './Basket.css';
import api from '../../configs/api';
import qs from 'qs';
import elvisApi from '../../helpers/elvisApi';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetList from '../../components/AssetList/AssetList';
import { NavLink } from 'react-router-dom';
import { AppStateConsumer } from '../../providers/AppState';
import GoogleAnalytics from 'react-ga';

class Basket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      assetList: [],
      sharesBasketList: localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [],    
      hierarchiesBasketList: localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [],    
      settings: {},
      ui: {
        assetsLoading: false,
        assetsLoadingSuccess: false,
      }
    };
  }

  get activeBasketList() {
    if (this.activeTab == 'hierarchy')
      return this.state.hierarchiesBasketList;
    if (this.activeTab == 'share')
      return this.state.sharesBasketList;
  }

  async search(query, sortBy) {
    this.setState({
      ui: {
        ...this.state.ui,
        assetsLoading: true,
      }
    });

    const requestApi = this.state.settings.general.useElvisProxyForAll ? api : elvisApi
    if (this.activeTab == 'hierarchy') {

      requestApi.search({ q: query, sort: sortBy }).then(result => {
        this.setState({
          query: query,
          assetList: result.hits.map(hit => ({
            id: hit.id,
            name: hit.metadata.name,
            thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
            url: '/',
            metadata: hit.metadata,
          })),
          ui: {
            ...this.state.ui,
            assetsLoading: false,
            assetsLoadingSuccess: true,
          }
        });
      }, error => {
        // TODO: Error handling
        this.setState({
          query: query,
          assetList: [],
          ui: {
            ...this.state.ui,
            assetsLoading: false,
            assetsLoadingSuccess: true,
          }
        });
        console.error(error);
      });

    } else {
      try {      
        let results;
        
        if (this.isPrivate) {
          results = await api.privateSharedSearch({ token: this.URLSearchParams.token }, {q: query});
        } else {
          results = await api.userSearch({q: query});
        }

        this.setState({
          query: query,
          assetList: results.hits.map(hit => ({
            id: hit.id,
            name: hit.metadata.name,
            thumbnail: api.getAssetThumbnail(hit, this.URLSearchParams.token),
            url: '/',
            metadata: hit.metadata,
          })),
          ui: {
            ...this.state.ui,
            assetsLoading: false,
            assetsLoadingSuccess: true,
          }
        });
      } catch(error) {
        if (error.status == 404) {
          this.setState({
            query: query,
            assetList: [],
            ui: {
              ...this.state.ui,
              assetsLoading: false,
              assetsLoadingSuccess: true,
            }
          });
        } else {
        // TODO: Improve error handling
          console.error('Couldn\'t fetch assets!');
          this.setState({
            query: query,
            assetList: [],
            ui: {
              ...this.state.ui,
              assetsLoading: false,
              assetsLoadingSuccess: true,
            }
          });
        }
      }
    }
  }

  toggleBasket(id, type) {
    let sharesBasketList = localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [];
    let hierarchiesBasketList = localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [];

    let basketList = this.activeTab == 'hierarchy' ? hierarchiesBasketList : sharesBasketList;
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }
    
    if (this.activeTab == 'hierarchy') {
      this.setState({ hierarchiesBasketList: basketList });
      localStorage.hierarchiesBasket = JSON.stringify(basketList);
    } else {
      this.setState({ sharesBasketList: basketList });
      localStorage.sharesBasket = JSON.stringify(basketList);
    }

    if (basketList.length > 0) {
      this.search(`id:${basketList.join(' OR id:')}`);
    } else {
      this.setState({
        assetList: [],
      })
    }
  }

  async componentDidMount() {
    await api.getSettings().then(settingsList => {

      const itemPerPage = parseInt(settingsList.find(({ name }) => name === 'general').config.itemPerPage, 10);
      const general = {
        ...settingsList.find(({ name }) => name === 'general').config,
        itemPerPage,
      };

      const sortingList = settingsList.find(({ name }) => name === 'assets-sorting-list').config.list;
      const assetMetadataList = settingsList.find(({ name }) => name === 'asset-item-metadata-list').config.list;

      const settings = {
        general,
        sortingList,
        assetMetadataList
      };

      this.setState({ settings }, () => {
        console.log(this.activeTab);
        if (this.activeBasketList && this.activeBasketList.length > 0) {
          this.search(`id:${this.activeBasketList.join(' OR id:')}`);
        } else {
          this.setState({
            ui: {
              ...this.state.ui,
              assetsLoading: false,
              assetsLoadingSuccess: true,
            }
          });
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    const currentParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const previousParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });

    if (currentParams.tab !== previousParams.tab) {
      this.search(`id:${this.activeBasketList.join(' OR id:')}`);
    }
  }

  get downloadURL() {
    if (this.isPrivate) {
      return api.getPrivateDownloadURL(this.URLSearchParams.token);
    } if (this.activeTab === 'share') {
      return api.getUserDownloadURL();
    } else {
      return api.getDownloadURL();
    }
  }

  download(e) {
    e.preventDefault();
    GoogleAnalytics.event({
      category: 'Download',
      action: `Downloaded Assets from ${this.activeTab} basket`,
      label: `asset ids:${this.state.assetList.map(asset => asset.id).join(',')} | date:${(new Date()).toDateString()}`,
    });
    window.open(`${this.downloadURL}?assetIds=${this.state.assetList.map(asset => asset.id).join(',')}`);
  }

  changeSorting(sortBy) {
    this.search(this.state.query, sortBy);
  }

  clearBasket(e) {
    e.preventDefault();
    localStorage.sharesBasket = '[]';
    localStorage.hierarchiesBasket = '[]';

    this.setState({
      sharesBasketList: [],
      hierarchiesBasketList: [],
      assetList: [],
    });
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  get isPrivate() {
    return !!this.URLSearchParams.token;
  }

  get activeTab() {
    if (this.URLSearchParams.tab=='share') {
      return 'share';
    }

    if (this.URLSearchParams.tab=='hierarchy') {
      return 'hierarchy';
    }

    if (this.URLSearchParams.token) {
      return 'share';
    }

    if (this.props.isHierarchiesActive) {
      return 'hierarchy';
    }

    if (this.props.isSharesActive) {
      return 'share';
    }

    return 'hierarchy';
  }

  render() {
    return (
      <div className='ems-container'>
        <Header
          settings={this.state.settings.general}
          isAuthenticated={true}
          token={this.URLSearchParams.token}/>
        <div className='ems-margin-top ems-basket ems-main'>
          <div className="ems-main-inner">
            {(this.props.isSharesActive && this.props.isHierarchiesActive) && <div className='ems-basket-tabs'>
              {this.props.isHierarchiesActive && <NavLink isActive={() => this.activeTab=='hierarchy'} className={`ems-pill ems-basket-tabs-item ${this.activeTab=='hierarchy' ? 'ems-basket-tabs-item-active' : ''}`} to={{ pathname: '/basket', search: '?tab=hierarchy' }}>Hierarchy Assets<span className="ems-pill-value">{this.state.hierarchiesBasketList.length}</span></NavLink>}
              {this.props.isSharesActive && <NavLink isActive={() => this.activeTab=='share'} className={`ems-pill ems-basket-tabs-item ${this.activeTab=='share' ? 'ems-basket-tabs-item-active' : ''}`} to={{ pathname: '/basket', search: '?tab=share' }}>Share Assets<span className="ems-pill-value">{this.state.sharesBasketList.length}</span></NavLink>}
            </div>}
            {this.state.settings.general && <AssetList
              itemPath={elvisApi.csrfToken ? 'item' : 'share-item'}
              ui={this.state.ui}
              type={this.activeTab}
              token={this.URLSearchParams.token}
              settings={this.state.settings}
              assetHeadline={<div className='ems-margin-bottom'><h2 className='ems-heading ems-asset-results-heading'>Basket</h2><div className='ems-basket-buttons'><button className='ems-button ems-button-downloadems-basket-download' onClick={e => this.download(e)}>Download basket</button> <button className='ems-button ems-button-basket-clear ems-basket-clear' onClick={e => this.clearBasket(e)}>Clear basket</button></div></div>}
              onChangeSorting={sortBy => this.changeSorting(sortBy)}
              assetList={this.state.assetList} 
              basketList={this.activeBasketList}
              toggleBasket={id => this.toggleBasket(id)}/>
            }
          </div>
        </div>
        <Footer settings={this.state.generalSiteSettings}/>
      </div>
    );
  }
}

const ConnectedBasket = props => (
  <AppStateConsumer>
    {({ isHierarchiesActive, isSharesActive }) => (
      <Basket
        {...props}
        isHierarchiesActive={isHierarchiesActive}
        isSharesActive={isSharesActive}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedBasket;
