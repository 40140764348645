import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import './Shares.css';
import qs from 'qs';
import cookies from '../../helpers/cookies';
import isEqual from 'lodash/fp/isEqual';
import api from '../../configs/api';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetList from '../../components/AssetList/AssetList';
import Pagination from '../../components/Pagination/Pagination';
import PlayAll from '../../components/PlayAll/PlayAll';
import Folders from '../Folders/Folders';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { marked } from 'marked';

class Shares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ui: {
        settingsLoading: false,
        settingsLoadingSuccess: false,
        
        sharesLoading: false,
        sharesLoadingSuccess: false,
        
        browseShareLoading: false,
        browseShareLoadingSuccess: false,

        assetsLoading: false,
        assetsLoadingSuccess: true,

        passwordRequired: false,
        showShares: true,

        showLegalNotice: false,
        legalNoticeChoice: '',
        legalText: '',
      },

      settings: {
        style: {},
      },
      shares: [],
      folders: [],
      result: {
        assets: [],
        facets: [],
        totalHits: 0
      },

      isPlayAllActive: false,

      basketList: localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [],
    };
  }

  async componentDidMount() {
    try {
      const settings = await this.loadSettings();
      await this.loadShares();
      if (this.activeShare) {
        
        if(this.activeShare.legal) {
          await this.showLegalNotice();
        }

        if (this.URLSearchParams.activeFolder) {
          await this.browseShare();
        }
        
        if (this.URLSearchParams.activeFolder || this.activeShare.files.length > 0) {
          await this.loadItems();
        }
      }
    } catch(error) {
      console.log(error);
    }
  }

  async componentDidUpdate(prevProps) {
    const currentActiveShareId = this.props.match && this.props.match.params.id;
    const previousActiveShareId = prevProps.match && prevProps.match.params.id;
    const currentURLSearchParams = this.URLSearchParams;
    const previousURLSearchParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });

    if (
      currentURLSearchParams.keyword !== previousURLSearchParams.keyword || 
      currentURLSearchParams.sortBy !== previousURLSearchParams.sortBy || 
      currentURLSearchParams.page !== previousURLSearchParams.page ||
      currentURLSearchParams.activeFolder !== previousURLSearchParams.activeFolder ||
      !isEqual(currentURLSearchParams.filters, previousURLSearchParams.filters) ||
      currentActiveShareId !== previousActiveShareId
    ) {
      try {
        if (currentActiveShareId !== previousActiveShareId || currentURLSearchParams.activeFolder !== previousURLSearchParams.activeFolder) {
          this.setState({ 
            ui: {
              ...this.state.ui,
              browseShareLoadingSuccess: false,
              assetsLoadingSuccess: false,
            },            
            result: {
              ...this.state.result,
              assets: [],
              totalHits: 0
            },
            folders: [],
          });

          if(this.activeShare.legal && currentActiveShareId !== previousActiveShareId) {
            await this.showLegalNotice();
          }

          if(currentURLSearchParams.activeFolder) {
            await this.browseShare();
          }
        }

        if (currentURLSearchParams.activeFolder || this.activeShare.files.length > 0) {
          this.setState({ 
            ui: {
              ...this.state.ui,
              assetsLoadingSuccess: false,
            },
            result: {
              ...this.state.result,
              assets: [],
              totalHits: 0
            },
          });

          await this.loadItems();
        }
      } catch(error) {
        console.log(error);
      }
    }
  }

  async loadSettings() {
    this.setState({
      ui: {
        ...this.state.ui,
        settingsLoading: true,
        settingsLoadingSuccess: false,
      }
    });

    try {
      const settingsList = await api.getSettings();

      const itemPerPage = parseInt(settingsList.find(({ name }) => name === 'general').config.itemPerPage, 10);
      const general = {
        ...settingsList.find(({ name }) => name === 'general').config,
        itemPerPage,
      };

      const sortingList = settingsList.find(({ name }) => name === 'assets-sorting-list').config.list;
      const assetMetadataList = settingsList.find(({ name }) => name === 'asset-item-metadata-list').config.list;
      const filterList = settingsList.find(({ name }) => name === 'share-filter-list').config.list;
      const style = settingsList.find(({ name }) => name === 'style').config;

      const settings = {
        general,
        sortingList,
        assetMetadataList,
        filterList,
        style
      };

      this.setState({
        ui: {
          ...this.state.ui,
          settingsLoading: false,
          settingsLoadingSuccess: true,
        },
        settings,
      });

      return settings;
    } catch(error) {
      console.log(error)
    }
  }

  async loadShares() {
    this.setState({
      ui: {
        ...this.state.ui,
        sharesLoading: true,
        sharesLoadingSuccess: false,
      }
    });


    try {
      let shares = [];

      if (api.token) {
        shares = await api.getMyShares().catch(() => [])
      }

      if (this.isPrivate) {
        const share = await api.getPrivateShare(this.props.match.params.token, sessionStorage.getItem(this.props.match.params.token));

        if(share.expired) {
          shares = shares.concat({
            title: 'Expired Share',
            expired: true,
            type: 'private',
            id: this.props.match.params.token,
            token: this.props.match.params.token
          });

          this.setState({
            ui: {
              ...this.state.ui,
              sharesLoading: false,
              sharesLoadingSuccess: true,
              shareExpired: true,
            }
          });
        } else {
          shares = shares.concat(share);
        }
      }

      this.setState({
        ui: {
          ...this.state.ui,
          sharesLoading: false,
          sharesLoadingSuccess: true,
        },
        shares 
      });

      return shares;
    } catch(error) {
      if (error.status === 404 && this.isPrivate) {
        this.setState({
          ui: {
            ...this.state.ui,
            passwordRequired: true,
          }
        });
      }

      console.log(error);
    }
  }

  async browseShare() {
    this.setState({
      ui: {
        ...this.state.ui,
        browseShareLoading: true,
        browseShareLoadingSuccess: false,
      }
    });

    try {
      let folders;

      if (this.isPrivate){
        folders = await api.browsePrivateShare(this.activeShare, this.URLSearchParams.activeFolder);
      } else {
        folders = await api.browseShare(this.activeShare, this.URLSearchParams.activeFolder);
      }

      this.setState({
        ui: {
          ...this.state.ui,
          browseShareLoading: false,
          browseShareLoadingSuccess: true,
        },
        folders 
      });

      return folders;
    } catch(error) {
      console.log(error);
    }
  }

  async loadAssets(query) {
    this.setState({
      ui: {
        ...this.state.ui,
        assetsLoading: true,
        assetsLoadingSuccess: false,
      },
      result: {
        ...this.state.result,
        assets: [],
        totalHits: 0
      }
    });

    try {
      const filters = {};

      Object.keys(query.filters).forEach(filterName => {
        filters[filterName] = query.filters[filterName].join(',');
      });

      let result;

      if (this.isPrivate) {
        result = await api.privateSharedSearch(this.activeShare, {
          q: query.query,
          facets: query.facets,
          sort: query.sortBy,
          num: query.num, 
          start: query.start,
          ...filters
        });
      } else {
        result = await api.sharedSearch(this.activeShare, {
          q: query.query,
          facets: query.facets,
          sort: query.sortBy,
          num: query.num, 
          start: query.start,
          ...filters
        });
      }

      this.setState({
        ui: {
          ...this.state.ui,
          assetsLoading: false,
          assetsLoadingSuccess: true,
        },
        result: {
          ...this.state.result,
          assets: result.hits.map(hit => ({
            id: hit.id,
            name: hit.metadata.name,
            thumbnail: api.getAssetThumbnail(hit, this.props.match.params.token),
            metadata: hit.metadata
          })),
          facets: result.facets,
          totalHits: result.totalHits,
        }
      });
    } catch(error) {
      console.log(error);      
    }
  }

  async loadItems() {
    try {
      const params = {
        keyword: this.URLSearchParams.keyword,
        filters: this.URLSearchParams.filters,
        sortBy: this.URLSearchParams.sortBy,
        page: this.URLSearchParams.page
      };

      const elvisQuery = this.populateQuery({
        shareType: this.activeShare.folders.length > 0 ? 'folder' : 'file',
        folder: this.URLSearchParams.activeFolder,
        settings: this.state.settings, 
        params
      });

      await this.loadAssets(elvisQuery);
    } catch(error) {
      console.log(error)
    }
  }

  populateQuery(params) {
    let query = '';

    if (params.shareType == 'folder') {
      query = params.folder ? `ancestorPaths:"${params.folder}"` : '';
    }

    return {
      query: `${query}${this.URLSearchParams.keyword ? ` ${this.URLSearchParams.keyword}` : ''}`,
      facets: this.state.settings.filterList.map(filter => filter.metafield).join(','),
      filters: this.URLSearchParams.filters || {},
      sortBy: this.URLSearchParams.sortBy ? this.URLSearchParams.sortBy : params.settings.sortingList.length > 0 ? params.settings.sortingList[0].metadata+'-'+params.settings.sortingList[0].defaultOrder : '',
      start: (parseInt(this.URLSearchParams.page, 10) || 0) * params.settings.general.itemPerPage,
      num: params.settings.general.itemPerPage,
    };
  }

  toggleBasket(id) {
    let basketList = localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [];
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }

    this.setState({ basketList });
    localStorage.sharesBasket = JSON.stringify(basketList);
  }

  handleSearch(params) {
    const newParams = {
      ...this.URLSearchParams,
      keyword: typeof params.keyword === 'undefined' ? this.URLSearchParams.keyword : params.keyword ,
      filters: typeof params.filters === 'undefined' ? this.URLSearchParams.filters : params.filters,
      sortBy: typeof params.sortBy === 'undefined' ? this.URLSearchParams.sortBy : params.sortBy,
      page: typeof params.page === 'undefined' ? this.URLSearchParams.page : params.page,
    };

    const hideFolders = !!params.keyword || !(params.keyword === '' && this.URLSearchParams.hideFolders === 'true');

    const queryString = qs.stringify({ ...newParams, hideFolders }, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  handleFilter(filters) {
    const filtersQuery = {};
    Object.keys(filters).forEach(filterName => {
      filtersQuery[`facet.${filterName}.selection`] = filters[filterName];
    });

    this.handleSearch({
      filters: filtersQuery
    });
  }

  toggleShares(e) {
    e.preventDefault();

    this.setState({
      ui: {
        ...this.state.ui,
        showShares: !this.state.ui.showShares,
      }
    });
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  get activeShare() {
    if(this.props.match && (this.props.match.params.id || this.props.match.params.token)) {
      if (this.isPrivate) {
        return this.state.shares.find(share => share.token === this.props.match.params.token);
      }

      return this.state.shares.find(share => share.id === this.props.match.params.id);
    } else {
      return this.state.shares[0];
    }
  }

  get folders() {
    return (
      this.URLSearchParams.activeFolder ? 
      this.state.folders.map(folder => folder.assetPath) : 
      this.activeShare ? 
      this.activeShare.folders.filter(folder => !this.activeShare.folders.find(f => folder != f && folder.startsWith(f))) : 
      []
    );
  }

  get isPrivate() {
    return !!this.props.match.params.token;
  }

  reset() {
     const newParams = {
      ...this.URLSearchParams,
      keyword: '',
      filters: {},
      sortBy: '',
      page: 0,
      hideFolders: false,
    };

    const queryString = qs.stringify(newParams, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  get playableAssets() {
    return this.state.result.assets.filter(asset => asset.metadata.assetDomain === 'audio' || asset.metadata.assetDomain === 'video');
  }

  togglePlayAll() {
    this.setState({
      isPlayAllActive: !this.state.isPlayAllActive,
    });
  }

  async loadLegalDocument(id) {
    try {
      const response = await api.getLegalNotice(id);
      if (response.status === 200) {
        const text = await response.text();
        return text;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  legalNoticeHTML() {
    return {
      __html: marked.parse(this.state.ui.legalText)
    }
  }

  async showLegalNotice() {
    const legalText = await this.loadLegalDocument(this.activeShare.id);

    let legalNoticeChoice = 'rejected';

    if (this.activeShare.legal.option === 'showOnce' && cookies.get(this.activeShare.id) === 'accepted') {
      legalNoticeChoice = 'accepted';
    }

    this.setState({
      ui: {
        ...this.state.ui,
        showLegalNotice: true,
        legalNoticeChoice,
        legalText: legalText ? legalText : 'No legal documents related to this share were found.'
      }
    });
  }

  hideLegalNotice() {
    this.setState({
      ui: {
        ...this.state.ui,
        showLegalNotice: false,
      }
    });
  }

  handleLegalNoticeAccept() {
    if (this.activeShare.legal.option === 'showOnce') {
     cookies.set(this.activeShare.id, 'accepted');
    }

    this.setState({
      ui: {
        ...this.state.ui,
        legalNoticeChoice: 'accepted'
      }          
    }, () => {
      this.hideLegalNotice();
    });
  }

  handleLegalNoticeReject() {
    if (this.activeShare.legal.option === 'showOnce') {
      cookies.set(this.activeShare.id, 'rejected');
    }
    this.setState({
      ui: {
        ...this.state.ui,
        legalNoticeChoice: 'rejected'
      }          
    }, () => {
      this.hideLegalNotice();
    });
  }

  isLegalNoticeVisible(shareId) {
    const share = this.state.shares.find(share => share.id === shareId);

    if (share.legal) {
    
      if (share.legal.option === 'showOnce') {
        return cookies.get(shareId) !== 'accepted';
      }

      return true;
    }

    return false;
  }

  isLegalNoticeAccepted(shareId) {
    const share = this.state.shares.find(share => share.id === shareId);
    if (share.legal) {
      return this.state.ui.legalNoticeChoice === 'accepted';
    }
    return true;
  }

  render() {
    const { ui } = this.state;

    if(this.state.ui.passwordRequired) {
      return <Redirect to={{ pathname: `/sign-in-to-share/${this.props.match.params.token}` }} />;
    }

    // if(this.state.ui.shareExpired) {
    //   {alert('This share has expired.')}
    //   return <Redirect to={{ pathname: `/` }} />;
    // }

    return (
      <div className='ems-container'>
        {
          this.activeShare && this.isLegalNoticeVisible(this.activeShare.id) && this.state.ui.showLegalNotice && 
            <ConfirmBox
              onReject={() => this.handleLegalNoticeReject()}
              onAccept={() => this.handleLegalNoticeAccept()}>
            <div dangerouslySetInnerHTML={this.legalNoticeHTML()}></div>
          </ConfirmBox>
        }
        <Header
          token={this.props.match.params.token}
          settings={this.state.settings.general}
          isAuthenticated={!!api.token}
          basketList={this.state.basketList}/>
        <div className='ems-shares-wrapper ems-main'>
          <div className="ems-main-inner">
            <div className='ems-margin-top ems-heading-wrapper ems-shares-heading-wrapper'>
              <h2 className='ems-heading ems-shares-heading'>Your Shares </h2><a className={`ems-button ems-toggle ems-button-icon ems-shares-toggle ${this.state.ui.showShares ? 'ems-shares-toggle-open' : 'ems-shares-toggle-hidden'}`} href='/' onClick={e => this.toggleShares(e)}>
                {!this.state.ui.showShares && <i className='far fa-plus-square' aria-hidden='true'></i>}
                {this.state.ui.showShares && <i className="far fa-minus-square" aria-hidden='true'></i>}
                </a>
            </div>
            {<div className="ems-share-list-wrapper">
              <div className={`ems-share-list ${this.state.ui.showShares ? 'ems-shares-list-open' : 'ems-shares-list-hidden'}`}>
                {this.state.shares.map(share => <NavLink key={share.id} className={`ems-pill ems-share-list-item ${this.activeShare.id === share.id ? 'active' : ''}`} to={share.type === 'private' ? `/shares/private/${share.token}` : `/shares/${share.id}`} title={`Shared by ${share.sharer} on ${share.createdAt}`}>{share.title}</NavLink>)}
              </div>
            </div>}
            {this.activeShare && !this.activeShare.expired && this.isLegalNoticeAccepted(this.activeShare.id) ?
              <React.Fragment>
               {!ui.sharesLoading && ui.sharesLoadingSuccess && <Folders
                  {...this.props}
                  ui={ui}
                  searchInputPlaceholder={this.state.settings.style.search.bar.placeholder}
                  searchButtonText={this.state.settings.style.search.button.text}
                  token={this.props.match.params.token}
                  activeShare={this.activeShare}
                  reset={() => this.reset()}
                  search={params => { this.handleSearch(params) }}
                  folders={this.folders}/>}

                {this.activeShare && <AssetList
                  playableAssets={this.playableAssets.length > 0}
                  togglePlayAll={() => this.togglePlayAll()}
                  ui={ui}
                  token={this.props.match.params.token}
                  activeShare={this.activeShare}
                  type='share'
                  filters={this.state.settings.filterList} facets={this.state.result.facets}
                  onChangeFilters={filters => this.handleFilter(filters)}
                  settings={this.state.settings}
                  collectionInURLQuery={false}
                  onChangeSorting={sortBy => this.handleSearch({ sortBy })}
                  assetHeadline={<h3 className='ems-heading ems-asset-results-heading'>{this.state.settings.style.assets.results.viewLabel || 'Assets'} {this.state.result.totalHits && <span className='ems-asset-results-heading-count'>{this.state.result.totalHits}</span>}</h3>}
                  assetList={this.state.result.assets}
                  basketList={this.state.basketList}
                  toggleBasket={id => this.toggleBasket(id)}
                />}
                {!ui.assetsLoading && ui.assetsLoadingSuccess && this.state.result.totalHits > 0 && 
                  <Pagination activePage={parseInt(this.URLSearchParams.page, 10) || 0} totalItem={this.state.result.totalHits} itemPerPage={this.state.settings.general.itemPerPage} />}
              </React.Fragment> : 
                (this.state.ui.legalNoticeChoice === 'rejected' ? 
                  <div className='ems-legal-notice-rejection'>You should accept legal notice to see this content.</div> :
                  (this.activeShare && this.activeShare.expired) &&
                    <div className='ems-share-error'>Sorry, but this share has expired.</div>)
              }
          </div>
        </div>
        {this.state.isPlayAllActive && <PlayAll share={this.activeShare} assets={this.playableAssets} token={this.props.match.params.token} close={() => { this.togglePlayAll() }} />}
        <Footer
          settings={this.state.settings.general}/>
      </div>
    );
  }
}

export default Shares;