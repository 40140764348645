import { useMutation } from "react-query"
import api from '../../../../configs/api';
import elvisApi from '../../../../helpers/elvisApi';
import cookies from '../../../../helpers/cookies';
import GoogleAnalytics from 'react-ga';

export const useLogin = ({ useProxy } = { useProxy: false }) => {
  return useMutation(
    ({ identifier, password }) => api.signIn(identifier, password), {
      onSuccess: (data, variables) => {
        localStorage.sharesBasket = '[]';
        localStorage.hierarchiesBasket = '[]';
        sessionStorage.userIdentifier = variables.identifier;

        GoogleAnalytics.event({
          category: 'User',
          action: `User logged in`,
          label: `user:${variables.identifier} | date:${(new Date()).toDateString()}`,
        });

        if (!useProxy) {
          const sessionExpiry = new Date();
          sessionExpiry.setTime(sessionExpiry.getTime() + (1000 * 60 * 60 * 24 * 3));

          elvisApi.login({
            username: variables.identifier,
            password: variables.password,
          }).then(responseElvis => {
            cookies.set('branding-csrf', responseElvis.csrfToken, { expires: sessionExpiry });
          }, responseElvis => {
            console.log('no elvis');
          });
        }
      }
    }
  );
}
