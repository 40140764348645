import elvisDriver from 'elvis-driver/browser';
import configs from '../configs/config';
import cookies from './cookies';


const Elvis = elvisDriver.createClient(configs['elvis.url']);

if (cookies.get('branding-csrf')) {
  Elvis.login({
    csrfToken: cookies.get('branding-csrf'),
  });
}

Elvis.download = function(ids, fileName = 'download') {
  window.open(`${this.__getRemoteURL('/zip')}/${fileName}?downloadKind=original&assetIds=${ids}`);
}

Elvis.preview = function(id, maxSize) {
  return `${this.__getRemoteURL('/preview')}/${id}/previews/maxWidth_${maxSize}_maxHeight_${maxSize}.jpg`;
}

Elvis._search = Elvis.search;

Elvis.search = function(params) {
  return Elvis._search(params)
    .then(
      response => {
        return Promise.resolve(response); 
      }, 
      error => { 
        if(error.loggedOut) { 
          window.location.replace('/#/sign-out');
        }
        
        return Promise.reject(error); 
      }
    );
}

export default Elvis;