import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './ItemView.css';
import api from '../../configs/api';
import qs from 'qs';
import elvisApi from '../../helpers/elvisApi';
import msToTime from '../../helpers/msToTime';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetList from '../../components/AssetList/AssetList';
import Zoom from '../../components/Zoom/Zoom';
import GoogleAnalytics from 'react-ga';
import Preview from '../../components/Preview/Preview';
import SpotifyPlayer from '../../components/SpotifyPlayer/SpotifyPlayer';

class ItemView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ui: {
        settingsLoading: false,
        settingsLoadingSuccess: false,

        assetsLoading: false,
        assetsLoadingSuccess: false,

        playOriginal: false,

        redirectToSignIn: false,
      },
      basketList: localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [],
      asset: null,
      relatedAssetLists: [],
      assetList: [],
      showZoom: false,
      settings: {
        siteName: '',
        footerHTML: '',
      },
    };
  }

  togglePlayOriginal() {
    this.setState({
      ui: {
        ...this.state.ui,
        playOriginal: !this.state.ui.playOriginal,
      }
    });
  }

  toggleBasket(id) {
    let basketList = localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [];
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }

    this.setState({ basketList });
    localStorage.hierarchiesBasket = JSON.stringify(basketList);
  }

  reset() {
    this.setState({
      asset: null,
      assetList: [],
    });
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  async loadSettings() {
    this.setState({
      ui: {
        ...this.state.ui,
        settingsLoading: true,
        settingsLoadingSuccess: false,
      }
    });

    try {
      const settingsList = await api.getSettings();

      const itemPerPage = parseInt(settingsList.find(({ name }) => name === 'general').config.itemPerPage, 10);
      const general = {
        ...settingsList.find(({ name }) => name === 'general').config,
        itemPerPage,
      };

      const sortingList = settingsList.find(({ name }) => name === 'assets-sorting-list').config.list;
      const assetMetadataList = settingsList.find(({ name }) => name === 'asset-view-metadata-list').config.list;
      const style = settingsList.find(({ name }) => name === 'style').config;

      const settings = {
        general,
        sortingList,
        assetMetadataList,
        style
      };

      this.setState({
        ui: {
          ...this.state.ui,
          settingsLoading: false,
          settingsLoadingSuccess: true,
        },
        settings,
      });

      return settings;
    } catch(error) {
      console.log(error)
    }
  }

  async fetchData() {
    this.reset();

    await this.loadSettings();

    const requestApi = this.state.settings.general.useElvisProxyForAll ? api : elvisApi
    requestApi.search({ q: `id:${this.props.match.params.id}` }).then(results => {
      if (results.hits && results.hits.length > 0) {
        const asset = results.hits[0];
        this.setState({ asset });

        if(this.URLSearchParams.relatedHierarchy) {
          api.getHierarchy(this.URLSearchParams.relatedHierarchy).then(hierarchy => {

            api.getRelatedAssetLists(hierarchy.relatedAssetLists).then(relatedAssetLists => {
              this.setState({
                ui: {
                  ...this.state.ui,
                  assetsLoading: true,
                  assetsLoadingSuccess: false,
                },
                relatedAssetLists: [],
              });
              
              relatedAssetLists.forEach(relatedAsset => {

                const elvisQuery = this.buildRelatedAssetsQuery(relatedAsset.query, this.state.asset.metadata);
        
                requestApi.search({ q: elvisQuery }).then(result => {
                  this.setState({
                    relatedAssetLists: this.state.relatedAssetLists.concat([{
                      title: relatedAsset.title,
                      assets: result.hits.map(hit => ({
                        id: hit.id,
                        name: hit.metadata.name,
                        thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits && hit.thumbnailHits.length > 0 ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
                        metadata: hit.metadata
                      }))
                    }]),
                    ui: {
                      ...this.state.ui,
                      assetsLoading: false,
                      assetsLoadingSuccess: true,
                    }
                  });
                });

              });

            });
          });
        }
      }
    })
    .catch(error => {
      if (error.status === 403) {
        this.setState({
          ui: {
            ...this.state.ui,
            redirectToSignIn: true,
          }
        });
      }
    })
  }

  buildRelatedAssetsQuery(rawQuery, queryParams) {    
    let query = rawQuery;

    Object.keys(queryParams).forEach((field, index) => {
      query = query.replace(new RegExp('{' + field + '}', 'gi'), queryParams[field]);
    });

    return query;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }

  downloadCollection() {
    const requestApi = this.state.settings.general.useElvisProxyForAll ? api : elvisApi
    requestApi.search({ q: `parentContainerIds:(${this.state.asset.id})` }).then(result => {
      requestApi.download([result.hits.map(hit => hit.id)]);
    });
  }

  get fileURL() {
    return api.getFileURL(this.state.asset.id);
  }

  downloadAsset() {
    window.open(`${this.fileURL}/${this.state.asset.metadata.name}?forceDownload=true`)
  }

  download(e) {
    e.preventDefault();

    const { assetType } = this.state.asset.metadata.assetType;

    GoogleAnalytics.event({
      category: 'Download',
      action: `Downloaded a ${assetType} from hierarchy item page`,
      label: `${assetType} id:${this.state.asset.id} | date:${(new Date()).toDateString()}`,
    });

    if (assetType === 'collection') {
      this.downloadCollection();
    } else {
      this.downloadAsset();
    }
  }

  copyURL() {
    var dummy = document.createElement('input'),
    text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    alert('Link to this asset copied to clipboard.')
  }

  componentDidMount() {
    this.fetchData();
  }

  renderMetadataValue(metadata) {
    const value = Array.isArray(metadata) ? 
      metadata.map((value, index)  => <span key={index} className='ems-value-multivalue ems-item-view-content-details-metadata-item-value-mutlivalue'>{value}</span>) : 
          typeof metadata === 'object' ? metadata.formatted : metadata;
    
    return value;
  }

  toggleZoom() {
    this.setState({
      showZoom: !this.state.showZoom,
    });
  }

  get isPlayOriginalAvailable() {
    return this.state.settings.general.playOriginal && this.isMedia && this.state.asset.metadata.assetType == 'mp4';
  }

  get originalSize() {
    if (this.state.asset) {
      return this.state.asset.metadata.width > this.state.asset.metadata.height ? this.state.asset.metadata.width : this.state.asset.metadata.height;
    }

    return null;
  }

  get isMedia() {
    return (
      this.state.asset.metadata.assetDomain === 'video' ||
      this.state.asset.metadata.assetDomain === 'audio'
    )
  }

  get preview() {
    const assetId = this.state.asset.previewUrl ? this.state.asset.id : this.state.asset.thumbnailHits && this.state.asset.thumbnailHits.length > 0 ? this.state.asset.thumbnailHits[0].id : null;

    if (!assetId) {
      return null
    } else if (this.isMedia) {
      return api.getMediaPreviewURL(assetId);
    } else {
      return api.getPreviewURL(assetId);
    }
  }

  get originalVideo() {
    const assetId = this.state.asset.previewUrl ? this.state.asset.id : this.state.asset.thumbnailHits && this.state.asset.thumbnailHits.length > 0 && this.state.asset.thumbnailHits[0].id;
    return api.getOriginalMedia(assetId);
  }

  render() {
    if (this.state.ui.redirectToSignIn) {
      return <Redirect
        to={{
          pathname: '/sign-in',
          state: { from: this.props.location }
        }}
      />;
    }

    const requestApi = this.state.settings.general && this.state.settings.general.useElvisProxyForAll ? api : elvisApi
    const addedToBasket = this.state.asset && this.state.basketList.includes(this.state.asset.id);
    let previewUri = this.state.asset && this.preview;

    const zoomSizes = {
      small: {
        src: requestApi.preview(this.props.match.params.id, 1600),
        size: 1600,
      },
      large: {
        src: requestApi.preview(this.props.match.params.id, 3200),
        size: 3200,
      },
      original: {
        src: requestApi.preview(this.props.match.params.id, this.originalSize),
        size: this.originalSize,
      }
    };

    if (this.state.asset && this.state.asset.metadata.fileType === 'PDF') {
      zoomSizes.max = {
        src: requestApi.preview(this.props.match.params.id, 6400),
        size: 6400,
      };
    }

    if (this.state.showZoom) {
      return <Zoom
        title={this.state.asset.metadata.name}
        content={this.state.asset.metadata.assetDomain === 'pdf' && <Preview type='asset' domain={this.state.asset.metadata.assetDomain} src={previewUri} asset={this.state.asset}/>}
        asset={zoomSizes} 
        close={() => this.toggleZoom()}/>;
    }

    return (
      <div className='ems-container'>
        <Header
          settings={this.state.settings.general}
          isAuthenticated={true}
          basketList={this.state.basketList}/>
        <div className='ems-item-view ems-item-view-hierarchy ems-main'>
          <div className="ems-main-inner">
            <div className='ems-item-view-navigation'>
              <button className="ems-button ems-button-back" onClick={e => { e.preventDefault(); this.props.history.goBack(); }}><i className='fas fa-arrow-left'></i> Back</button>
            </div>
            {this.state.asset && <div className={`ems-item-view-wrapper ems-item-view-hierarchy-content ${this.state.ui.playOriginal ? 'ems-item-view-hierarchy-content-play-original' : ''}`} data-extension={this.state.asset.metadata.extension} data-asset-type={this.state.asset.metadata.assetType} data-asset-kind={this.state.asset.metadata.assetDomain}>
              {this.state.asset.metadata.assetType !== 'collection' && <h3 className='ems-heading ems-item-view-heading ems-item-view-hierarchy-content-heading'>{this.state.asset.metadata.title ? this.state.asset.metadata.title : this.state.asset.metadata.name}</h3>}
              {this.state.asset.metadata.assetType !== 'collection' && <div className='ems-item-view-content'>
                <div className='ems-item-view-content-image ems-item-view-content-image-clickable' onClick={() => this.state.asset.metadata.assetDomain !== 'video' && this.state.asset.metadata.assetDomain !== 'audio' && this.state.asset.metadata.assetDomain !== 'pdf' && this.toggleZoom()} data-previewstate={this.state.asset.metadata.previewState}>
                  {!this.state.ui.playOriginal && <Preview type='asset' domain={this.state.asset.metadata.assetDomain} src={previewUri} asset={this.state.asset}/>}

                  {this.state.ui.playOriginal && 
                    <div className='ems-item-view-play-original'>
                      <Preview type='asset' domain={this.state.asset.metadata.assetDomain} src={this.originalVideo} asset={this.state.asset}/>
                    </div>
                  }
                </div>
                <div className='ems-item-view-content-details'>
                  <div className='ems-item-view-content-details-buttons'>
                    {!this.isMedia && <button className='ems-button ems-button-icon ems-button-zoom ems-item-view-content-button' onClick={e => this.toggleZoom()} title='Zoom'>
                    <span><i className='fas fa-search-plus'></i> <span className="ems-button-text">Zoom</span></span>
                    </button> }
                    {this.isPlayOriginalAvailable && <button className={`ems-button ems-button-icon ems-item-view-content-button ems-item-view-content-button-play-type ${this.state.ui.playOriginal ? 'ems-item-view-content-button-play-type-preview' : 'ems-item-view-content-button-play-type-original'}`} onClick={() => { this.togglePlayOriginal() }}>
                      <span><i className='fas fa-film'></i> <span className='ems-button-text'>{this.state.ui.playOriginal ? 'Play Preview' : 'Play High Res Original'}</span></span>
                    </button>}
                    <button
                      className='ems-button ems-button-icon ems-button-basket ems-item-view-content-button' 
                      onClick={e => this.toggleBasket(this.state.asset.id)}
                      title={addedToBasket ? 'Remove from basket' : 'Add to basket'}>
                      {addedToBasket ? 
                        <span><i className='fas fa-shopping-basket error'></i> <span className="ems-button-text">Remove from Basket</span></span> :
                        <span><i className='fas fa-shopping-basket'></i> <span className="ems-button-text">Add to basket</span></span>
                      }
                    </button>  
                    <button className='ems-button ems-button-icon ems-button-download ems-item-view-content-button ' onClick={e => this.download(e)} title='Download'>
                      <span><i className='fas fa-arrow-down'></i> <span className="ems-button-text">Download</span></span>
                    </button>
                    <button className='ems-button ems-button-icon ems-button-copy ems-item-view-content-button' onClick={(e => this.copyURL(e))} title='Copy URL'>
                      <span><i className='fas fa-copy'></i> <span className="ems-button-text">Copy URL</span></span>
                    </button>
                  </div>
                  {this.state.settings.general.spotify && this.state.settings.general.spotify.active && this.state.asset.metadata[this.state.settings.general.spotify.metadatafield] &&
                    <SpotifyPlayer ISRC={this.state.asset.metadata[this.state.settings.general.spotify.metadatafield]} />
                  }
                  {this.state.settings.assetMetadataList.length > 0 && <div className='ems-metadata-list ems-item-view-content-details-metadata'>
                    {this.state.settings.assetMetadataList.map(item => 
                      this.state.asset.metadata[item.metadata] ? 
                        <div key={item.metadata} data-metafield={item.name} className='ems-metadata-list-item ems-item-view-content-details-metadata-item'>
                            <span className='ems-metadata-field ems-item-view-content-details-metadata-item-title'>{item.name}</span>
                            <span className='ems-metadata-value ems-value ems-item-view-content-details-metadata-item-value'>
                              { 
                                item.metadata === 'audioLength' || 
                                item.metadata === 'videoLength' ? 
                                msToTime(this.state.asset.metadata[item.metadata]) :
                                this.renderMetadataValue(this.state.asset.metadata[item.metadata])
                              }
                            </span>
                        </div> : false)}
                  </div>}
                </div>
              </div>}
              {this.state.relatedAssetLists.map((relatedAssetList, index) => <AssetList
                  key={index}
                  relatedHierarchy={this.URLSearchParams.relatedHierarchy}
                  hideSorting={true}
                  ui={this.state.ui}
                  filters={[]}
                  type='hierarchy'
                  settings={this.state.settings}
                  assetList={relatedAssetList.assets}
                  basketList={this.state.basketList}
                  toggleBasket={id => this.toggleBasket(id)}
                  assetHeadline={
                    <h3 className='ems-title ems-asset-list-title'>
                      {relatedAssetList.title} 
                    </h3>
                  }
                />)}

            </div>}
          </div>
        </div>
        {this.state.settings && <Footer
            settings={this.state.settings.general}/>}
      </div>
    );
  }
}

export default ItemView;
