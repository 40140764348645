import React, { Component } from 'react';
import './Hierarchies.css';
import qs from 'qs';
import isEqual from 'lodash/fp/isEqual';
import api from '../../configs/api';
import elvisApi from '../../helpers/elvisApi';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetList from '../../components/AssetList/AssetList';
import Pagination from '../../components/Pagination/Pagination';
import HierarchyBanner from '../../components/HierarchyBanner/HierarchyBanner';
import Hierarchy from '../Hierarchy/Hierarchy';
import { matchPath } from 'react-router-dom';
import PlayAll from '../../components/PlayAll/PlayAll';
import HierarchyService from './services/HierarchyService';
import { AppStateConsumer } from '../../providers/AppState';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import ArticleSectionContainer from '../../components/ArticleSectionContainer/ArticleSectionContainer';

class Hierarchies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ui: {
        hierarchyLoading: false,
        hierarchyLoadingSuccess: false,

        collectionLoading: false,
        collectionLoadingSuccess: false,

        assetsLoading: false,
        assetsLoadingSuccess: false,
      },

      hierarchy: {},
      collection: {},
      result: {
        assets: [],
        facets: [],
        totalHits: 0
      },

      basketList: localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [],

      isPlayAllActive: false,
    };
  }

  componentDidMount() {
    if  (this.props.settingsLoadingSuccess) {
      this.init();
    }
  }

  async init() {
    try {
      this.hierarchyService = new HierarchyService(this.settings.general.useElvisProxyForAll);
      const activeHierarchy = await this.loadHierarchies(this.settings);
      await this.loadItems(activeHierarchy, this.settings);
    } catch(error) {
      console.error(error);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.settingsLoadingSuccess && (prevProps.settingsLoadingSuccess !== this.props.settingsLoadingSuccess)) {
      this.init();
    }

    const currentActiveHierarchyName = this.activeHierarchyName;

    const { params: { path: previousActiveHierarchyName } } = matchPath(prevProps.location.pathname, {
      path: '/hierarchies/:path*',
      exact: false,
      strict: true
    });

    const currentURLSearchParams = this.URLSearchParams;
    const previousURLSearchParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });

    if (
      currentURLSearchParams.keyword !== previousURLSearchParams.keyword || 
      currentURLSearchParams.sortBy !== previousURLSearchParams.sortBy || 
      currentURLSearchParams.page !== previousURLSearchParams.page ||
      currentURLSearchParams.viewCollection !== previousURLSearchParams.viewCollection ||
      !isEqual(currentURLSearchParams.filters, previousURLSearchParams.filters) ||
      currentActiveHierarchyName !== previousActiveHierarchyName
    ) {
      try {
        if (currentActiveHierarchyName !== previousActiveHierarchyName) {
          this.setState({ 
            ui: {
              ...this.state.ui,
            },
            hierarchy: {},
            collection: {},
          });

          await this.loadHierarchies(this.settings);
        }

        this.setState({ 
          ui: {
            ...this.state.ui,
            assetsLoadingSuccess: false,
          },
          result: {
            ...this.state.result,
            assets: [],
            totalHits: 0
          },
        });

        await this.loadItems(this.state.hierarchy, this.settings);
      } catch(error) {
        throw error;
      }
    }
  }

  async loadHierarchies(settings) {
    try {
      const activeHierarchyName = this.activeHierarchyName || settings.general.defaultHierarchyNode;
      const activeHierarchy = await this.loadHierarchy(activeHierarchyName);
      return activeHierarchy;
    } catch(error) {
      throw error;
    }
  }

  async loadItems(activeHierarchy, settings) {
    try {
      const params = {
        keyword: this.URLSearchParams.keyword,
        filters: this.URLSearchParams.filters,
        sortBy: this.URLSearchParams.sortBy,
        page: this.URLSearchParams.page
      };

      let collection;

      if (this.URLSearchParams.viewCollection) {
        collection = await this.loadCollection(this.URLSearchParams.viewCollection);
      }

      const elvisQuery = this.populateQuery({
        activeHierarchy,
        settings: settings, 
        params,
        collection
      });

      await this.loadAssets(elvisQuery);
    } catch(error) {
      throw error;
    }
  }

  populateQuery(params) {
    const query = params.collection ? `relatedTo:${params.collection.id} relationTarget:child relationType:contains` : params.activeHierarchy.query;

    return {
      query: `${query} ${this.URLSearchParams.keyword ? `${this.URLSearchParams.keyword}` : ''}`,
      facets: params.activeHierarchy.filters.map(filter => filter.metafield).join(','),
      filters: this.URLSearchParams.filters || {},
      sortBy: this.URLSearchParams.sortBy ? this.URLSearchParams.sortBy : params.settings.sortingList.length > 0 ? params.settings.sortingList[0].metadata+'-'+params.settings.sortingList[0].defaultOrder : '',
      start: (parseInt(this.URLSearchParams.page, 10) || 0) * (params.activeHierarchy.numAssetsToshow || params.settings.general.itemPerPage),
      num: params.activeHierarchy.numAssetsToshow || params.settings.general.itemPerPage,
    };
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  get activeHierarchyName() {
    const match = matchPath(this.props.location.pathname, {
      path: '/hierarchies/:path*',
      exact: false,
      strict: true
    });

    return match.params.path;
  }

  get settings() {
    if (!this.props.settingsLoadingSuccess) {
      return [];
    }

    const settingsList = this.props.settings;

    const itemPerPage = parseInt(settingsList.find(({ name }) => name === 'general').config.itemPerPage, 10);
    const general = {
      ...settingsList.find(({ name }) => name === 'general').config,
      itemPerPage,
    };

    const sortingList = settingsList.find(({ name }) => name === 'assets-sorting-list').config.list;
    const assetMetadataList = settingsList.find(({ name }) => name === 'asset-item-metadata-list').config.list;
    const style = settingsList.find(({ name }) => name === 'style').config;

    return {
      general,
      sortingList,
      assetMetadataList,
      style
    };
  }

  async loadHierarchy(hierarchyName) {
    this.setState({
      ui: {
        ...this.state.ui,
        hierarchyLoading: true,
        hierarchyLoadingSuccess: false,
      }
    });

    try {
      const hierarchy = await api.getHierarchy(hierarchyName);

      this.setState({
        ui: {
          ...this.state.ui,
          hierarchyLoading: false,
          hierarchyLoadingSuccess: true,
        },
        hierarchy
      });

      return hierarchy;
    } catch(error) {
      throw new Error(error);
    }
  }

  async loadCollection(collectionId) {
    this.setState({
      ui: {
        ...this.state.ui,
        collectionLoading: true,
        collectionLoadingSuccess: false,
      }
    });

    try {
      const collection = await this.hierarchyService.getCollection(collectionId);

      this.setState({
        ui: {
          ...this.state.ui,
          collectionLoading: false,
          collectionLoadingSuccess: true,
        },
        collection,
      });

      return collection;
    } catch(error) {
      console.log(error);      
    }
  }

  async loadAssets(query) {
    this.setState({
      ui: {
        ...this.state.ui,
        assetsLoading: true,
        assetsLoadingSuccess: false,
      },
      result: {
        ...this.state.result,
        assets: [],
        totalHits: 0
      }
    });

    try {
      const result = await this.hierarchyService.loadAssets(query);

      this.setState({
        ui: {
          ...this.state.ui,
          assetsLoading: false,
          assetsLoadingSuccess: true,
        },
        result: {
          ...this.state.result,
          ...result,
        }
      });
    } catch(error) {
      console.log(error);      
    }
  }

  toggleBasket(id) {
    let basketList = localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [];
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }

    this.setState({ basketList });
    localStorage.hierarchiesBasket = JSON.stringify(basketList);
  }

  handleSearch(params) {
    const newParams = {
      ...this.URLSearchParams,
      keyword: typeof params.keyword === 'undefined' ? this.URLSearchParams.keyword : params.keyword ,
      filters: typeof params.filters === 'undefined' ? this.URLSearchParams.filters : params.filters,
      sortBy: typeof params.sortBy === 'undefined' ? this.URLSearchParams.sortBy : params.sortBy,
      page: typeof params.page === 'undefined' ? this.URLSearchParams.page : params.page,
    };

    const hideHierarchies = !!params.keyword || !(params.keyword === '' && this.URLSearchParams.hideHierarchies === 'true');

    const queryString = qs.stringify({ ...newParams, hideHierarchies }, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  handleFilter(filters) {
    const filtersQuery = {};
    Object.keys(filters).forEach(filterName => {
      filtersQuery[`facet.${filterName}.selection`] = filters[filterName];
    });

    this.handleSearch({
      filters: filtersQuery
    });
  }

  reset() {
     const newParams = {
      ...this.URLSearchParams,
      keyword: '',
      filters: {},
      sortBy: '',
      page: 0,
      hideHierarchies: false,
    };

    const queryString = qs.stringify(newParams, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  get playableAssets() {
    return this.state.result.assets.filter(asset => asset.metadata.assetDomain === 'audio' || asset.metadata.assetDomain === 'video');
  }

  togglePlayAll() {
    this.setState({
      isPlayAllActive: !this.state.isPlayAllActive,
    });
  }

  render() {
    const { ui } = this.state;

    return (
      <ErrorBoundary>
        <div className='ems-container'>
          {this.state.hierarchy.backgroundId && <Helmet>
            <style type='text/css'>
            {`body {
              background-image: url(${api.getPreviewURL(this.state.hierarchy.backgroundId)});
              background-size: cover;
              background-repeat: no-repeat;
              background-attachment: fixed;
            }` }
            </style>
          </Helmet>}

          <Header
            settings={this.settings.general}
            isAuthenticated={true}
            basketList={this.state.basketList}/>
            <div className='ems-hierarchies-wrapper ems-main'>
              <div className="ems-main-inner">
              {!ui.hierarchyLoading && ui.hierarchyLoadingSuccess && this.state.hierarchy.articleSettings && 
                <ArticleSectionContainer articleSettings={this.state.hierarchy.articleSettings} placement='top'/>}

              {this.state.hierarchy.bannerSettings && <HierarchyBanner banner={this.state.hierarchy.bannerSettings}/>}

                {!ui.hierarchyLoading && ui.hierarchyLoadingSuccess && <Hierarchy
                  {...this.props}
                  settings={this.settings}
                  searchSettings={this.settings.style.search}
                  defaultHeadline={this.settings.style.hierarchies.results.viewLabel}
                  reset={() => this.reset()}
                  search={params => { this.handleSearch(params) }}
                  breadcrumb={this.state.hierarchy.breadcrumb}         
                  collection={this.state.collection}
                  hierarchy={this.state.hierarchy}/>}

                {!ui.hierarchyLoading && ui.hierarchyLoadingSuccess && (this.state.hierarchy.showAssetList || this.URLSearchParams.keyword || this.URLSearchParams.viewCollection) &&
                  <AssetList
                    playableAssets={this.playableAssets.length > 0}
                    togglePlayAll={() => this.togglePlayAll()}
                    ui={{
                      settingsLoading: this.props.settingsLoading,
                      settingsLoadingSuccess: this.props.settingsLoadingSuccess,
                      ...ui
                    }}
                    type='hierarchy'
                    relatedHierarchy={this.state.hierarchy.path}
                    filters={this.state.hierarchy.filters} facets={this.state.result.facets}
                    onChangeFilters={filters => this.handleFilter(filters)}
                    settings={this.settings}
                    collectionInURLQuery={true}
                    onChangeSorting={sortBy => this.handleSearch({ sortBy })}
                    assetHeadline={<h3 className='ems-heading ems-asset-list-heading'>{this.state.hierarchy.assetHeadline ? this.state.hierarchy.assetHeadline : this.settings.style.assets.results.viewLabel || 'Assets'} {this.state.result.totalHits > 0 && <span className='ems-asset-list-heading-count'>{this.state.result.totalHits}</span>}</h3>}
                    assetList={this.state.result.assets}
                    basketList={this.state.basketList}
                    toggleBasket={id => this.toggleBasket(id)}
                  />}
                {!ui.assetsLoading && ui.assetsLoadingSuccess && (this.state.hierarchy.showAssetList || this.URLSearchParams.keyword || this.URLSearchParams.viewCollection) && this.state.result.totalHits > 0 && 
                  <Pagination activePage={parseInt(this.URLSearchParams.page, 10) || 0} totalItem={this.state.result.totalHits} itemPerPage={this.state.hierarchy.numAssetsToshow || this.settings.general.itemPerPage} />}
                
                {!ui.hierarchyLoading && ui.hierarchyLoadingSuccess && this.state.hierarchy.articleSettings && 
                  <ArticleSectionContainer articleSettings={this.state.hierarchy.articleSettings} placement='bottom'/> }             
              </div>
            </div>
          {this.state.isPlayAllActive && <PlayAll assets={this.playableAssets} close={() => { this.togglePlayAll() }} />}
          <Footer
            settings={this.settings.general}/>
        </div>
      </ErrorBoundary>
    );
  }
}

const ConnectedHierarchies = props => (
  <AppStateConsumer>
    {({ settings, settingsLoading, settingsLoadingSuccess }) => (
      <Hierarchies
        {...props}
        settings={settings}
        settingsLoading={settingsLoading}
        settingsLoadingSuccess={settingsLoadingSuccess}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedHierarchies;
