import React, { Component } from 'react';
import './ShareItemView.css';
import qs from 'qs';
import api from '../../configs/api';
import msToTime from '../../helpers/msToTime';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AssetList from '../../components/AssetList/AssetList';
import Zoom from '../../components/Zoom/Zoom';
import Player from '../../components/Player/Player';
import GoogleAnalytics from 'react-ga';
import Preview from '../../components/Preview/Preview';

class ShareItemView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ui: {
        playOriginal: false,
      },
      basketList: localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [],
      asset: null,
      showZoom: false,
      assetMetadataList: [],
      assetList: [],
      generalSiteSettings: {
        siteName: '',
        footerHTML: '',
      },
    };
  }

  toggleBasket(id) {
    let basketList = localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [];
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }

    this.setState({ basketList });
    localStorage.sharesBasket = JSON.stringify(basketList);
  }

  reset() {
    this.setState({
      asset: null,
      assetList: [],
    });
  }

  get isPlayOriginalAvailable() {
    return this.state.generalSiteSettings.playOriginal && this.isMedia && this.state.asset.metadata.assetType == 'mp4';
  }

  togglePlayOriginal() {
    this.setState({
      ui: {
        ...this.state.ui,
        playOriginal: !this.state.ui.playOriginal,
      }
    });
  }

  async fetchData() {
    this.reset();

    await api.getSettings().then(settings => {
      this.setState({
        assetMetadataList: settings.find(({ name }) => name === 'asset-view-metadata-list').config.list,        
        generalSiteSettings: settings.find(({ name }) => name === 'general').config,
      })
    });

    if (this.isPrivate) {
      await api.getPrivateShare(this.URLSearchParams.token, sessionStorage.getItem(this.URLSearchParams.token)).then(share => {
        this.setState({ share });
      });
    } else {
      await api.getShare(this.props.match.params.share_id).then(share => {
        this.setState({ share });
      });
    }

    let results;
    
    if (this.isPrivate) {
      results = await api.privateSharedSearch({ token: this.URLSearchParams.token }, {q: `id:${this.props.match.params.id}`});
    } else {
      results = await api.sharedSearch(this.state.share, {q: `id:${this.props.match.params.id}`});
    }

    // const requestApi = this.state.generalSiteSettings.useElvisProxyForAll ? api : elvisApi

    if (results.hits && results.hits.length > 0) {
      const asset = results.hits[0];
      this.setState({ asset });

      if (asset.metadata.assetType === 'collection') {
        // requestApi.search({ q: `relatedTo:${this.props.match.params.id} relationTarget:child relationType:contains` }).then(result => {
        //   this.setState({
        //     assetList: result.hits.map(hit => ({
        //       id: hit.id,
        //       name: hit.metadata.name,
        //       thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
        //       metadata: hit.metadata,
        //     }))
        //   });
        // });
      }
    }
  
    // a solution to support hierachies item view needed
    //////////////////////////////////////////////////////
    // requestApi.search({ q: `id:${this.props.match.params.id}` }).then(results => {
    //   if (results.hits && results.hits.length > 0) {
    //     const asset = results.hits[0];
    //     this.setState({ asset });

    //     if (asset.metadata.assetType === 'collection') {
    //       requestApi.search({ q: `relatedTo:${this.props.match.params.id} relationTarget:child relationType:contains` }).then(result => {
    //         this.setState({
    //           assetList: result.hits.map(hit => ({
    //             id: hit.id,
    //             name: hit.metadata.name,
    //             thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
    //             metadata: hit.metadata,
    //           }))
    //         });
    //       });
    //     }
    //   }
    // });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }

  get downloadURL() {
    if (this.isPrivate) {
      return api.getPrivateDownloadURL(this.URLSearchParams.token);
    } else {
      return api.getSharedDownloadURL(this.state.share);
    }
  }

  downloadCollection() {
    window.open(`${this.downloadURL}?q=parentContainerIds:${this.state.asset.id}`)
  }

  get fileURL() {
    if (this.isPrivate) {
      return api.getPrivateFileURL(this.state.asset.id, this.URLSearchParams.token);
    } else {
      return api.getSharedFileURL(this.state.share, this.state.asset.id);
    }
  }

  downloadAsset() {
    window.open(`${this.fileURL}/${this.state.asset.metadata.name}?forceDownload=true`)
  }

  download(e) {
    e.preventDefault();

    const { assetType } = this.state.asset.metadata.assetType;

    GoogleAnalytics.event({
      category: 'Download',
      action: `Downloaded a ${assetType} from hierarchy item page`,
      label: `${assetType} id:${this.state.asset.id} | date:${(new Date()).toDateString()}`,
    });

    if (assetType === 'collection') {
      this.downloadCollection();
    } else {
      this.downloadAsset();
    }
  }

  copyURL() {
    var dummy = document.createElement('input'),
    text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    alert('Link to this asset copied to clipboard.')
  }

  componentDidMount() {
    this.fetchData();
  }

  renderMetadataValue(metadata) {
    const value = Array.isArray(metadata) ? 
      metadata.map((value, index)  => <span key={index} className='ems-item-view-content-details-metadata-item-value-mutlivalue'>{value}</span>) : 
          typeof metadata === 'object' ? metadata.formatted : metadata;
    
    return value;
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  get isPrivate() {
    return !!this.URLSearchParams.token;
  }

  get isStream() {
    return (
      this.state.share &&
      this.state.share.stream &&
      Object.keys(this.state.share.stream).filter(metadata => this.state.share.stream[metadata].includes(this.state.asset.metadata[metadata])).length
    )
  }

  get isMedia() {
    return (
      this.state.asset.metadata.assetDomain === 'video' ||
      this.state.asset.metadata.assetDomain === 'audio'
    )
  }

  get thumbnail() {
    if (this.isPrivate) {
      return api.getPrivateThumbnailURL(this.state.asset.id, this.URLSearchParams.token);
    } else {
      return api.getThumbnailURL(this.state.asset.id);
    }
  }

  get preview() {
    const assetId = this.state.asset.previewUrl ? this.state.asset.id : this.state.asset.thumbnailHits && this.state.asset.thumbnailHits.length > 0 ? this.state.asset.thumbnailHits[0].id : null;

    if (!assetId) {
      return null
    } else if (this.isMedia) {
      if (this.isPrivate) {
        if (this.state.ui.playOriginal) {
          return api.getPrivateOriginalMedia(assetId, this.URLSearchParams.token)
        } else {
          return api.getPrivateMediaPreviewURL(assetId, this.URLSearchParams.token)
        }
      } else {
        if (this.state.ui.playOriginal) {
          return api.getOriginalMedia(assetId);
        } else {
          return api.getMediaPreviewURL(assetId);
        }
      }
    } else {
      if (this.isPrivate) {
        return api.getPrivatePreviewURL(assetId, this.URLSearchParams.token);
      } else {
        return api.getPreviewURL(assetId);
      }
    }
  }

  getCustomPreview(size) {
    if (this.state.asset) {
      if (this.isPrivate) {
        return api.getPrivateCustomPreviewURL(this.state.asset.id, size,this.URLSearchParams.token);
      } else {
        return api.getCustomPreviewURL(this.state.asset.id, size);
      }
    }
  }

  toggleZoom() {
    this.setState({
      showZoom: !this.state.showZoom,
    });
  }

  get originalSize() {
    if (this.state.asset) {
      return this.state.asset.metadata.width > this.state.asset.metadata.height ? this.state.asset.metadata.width : this.state.asset.metadata.height;
    }
    
    return null;
  }

  render() {
    const addedToBasket = this.state.asset && this.state.basketList.includes(this.state.asset.id);
    let previewUri = this.state.asset && this.preview;

    const zoomSizes = {
      small: {
        src: this.getCustomPreview(1600),
        size: 1600,
      }, 
      large: {
        src: this.getCustomPreview(3200),
        size: 3200,
      },
      original: {
        src: this.getCustomPreview(this.originalSize),
        size: this.originalSize,
      }
    };

    if (this.state.asset && this.state.asset.metadata.fileType === 'PDF') {
      zoomSizes.max = {
        src: this.getCustomPreview(6400),
        size: 6400,
      };
    }

    if (this.state.showZoom) {
      return <Zoom 
        title={this.state.asset.metadata.name} 
        asset={zoomSizes} 
        close={() => this.toggleZoom()}/>;
    }

    return (
      <div className='ems-container'>
        <Header
          token={this.URLSearchParams.token}
          settings={this.state.generalSiteSettings}
          isAuthenticated={true}
          basketList={this.state.basketList}/>
        <div className='ems-item-view ems-main ems-item-view-share'>
          <div className='ems-main-inner'>
            <div className='ems-item-view-navigation'>
            <a href='#' onClick={e => { e.preventDefault(); this.props.history.goBack(); }}><i className='fas fa-arrow-left'></i> Back</a>
          </div>
            {this.state.asset && <div className={`ems-item-view-share-content ${this.state.ui.playOriginal ? 'ems-item-view-share-content-play-original' : ''}`} data-asset-type={this.state.asset.metadata.extentsion} data-asset-kind={this.state.asset.metadata.assetDomain} >
            {this.state.asset.metadata.assetType !== 'collection' && <h3 className='ems-item-view-title ems-item-view-share-content-title'>{this.state.asset.metadata.name}</h3>}
            {this.state.asset.metadata.assetType !== 'collection' && <div className='ems-item-view-content'>
              <div className='ems-item-view-content-image ems-item-view-content-image-clickable' onClick={() => !this.isMedia && this.toggleZoom()}>
                {!this.state.ui.playOriginal && <Preview type='asset' domain={this.state.asset.metadata.assetDomain} src={previewUri} asset={this.state.asset} share={this.state.share} token={this.URLSearchParams.token}/>}

                {this.state.ui.playOriginal && 
                  <div className='ems-item-view-play-original'>
                    <Preview type='asset' domain={this.state.asset.metadata.assetDomain} src={previewUri} asset={this.state.asset} share={this.state.share} token={this.URLSearchParams.token}/>
                  </div>
                }
              </div>
              <div className='ems-item-view-content-details'>
                <div className='ems-item-view-content-details-buttons'>
                  {!this.isStream && !this.isMedia && <button className='ems-item-view-content-button ems-button' onClick={e => this.toggleZoom()} title='Zoom'>
                    Zoom
                  </button>}
                  {this.isPlayOriginalAvailable && <button className={`ems-item-view-content-button ems-button ems-item-view-content-button-play-type ${this.state.ui.playOriginal ? 'ems-item-view-content-button-play-type-preview' : 'ems-item-view-content-button-play-type-original'}`} onClick={() => { this.togglePlayOriginal() }}>
                      <span><i className='fas fa-film'></i> <span className='ems-button-text'>{this.state.ui.playOriginal ? 'Play Preview' : 'Play High Res Original'}</span></span>
                  </button>}
                  {!this.isStream && <button
                    className='ems-item-view-content-button ems-button' 
                    onClick={e => this.toggleBasket(this.state.asset.id)}
                    title={addedToBasket ? 'Remove from basket' : 'Add to basket'}>
                    {addedToBasket ? 
                      <i className='fas fa-shopping-basket error'></i>
                         :
                      <i className='fas fa-shopping-basket'></i>
                    }
                  </button>} 
                  {!this.isStream && <button className='ems-item-view-content-button ems-button' onClick={e => this.download(e)} title='Download'>
                    <i className='fas fa-arrow-down'></i>
                  </button>}
                  {!this.isPrivate && <button className='ems-item-view-content-button ems-button' onClick={(e => this.copyURL(e))} title='Copy URL'>
                    <i className='fas fa-copy'></i>
                  </button>}
                </div>
                {this.state.assetMetadataList.length > 0 && <div className='ems-item-view-content-details-metadata'>
                  {this.state.assetMetadataList.map(item => 
                    this.state.asset.metadata[item.metadata] ? 
                      <div key={item.metadata} className='ems-item-view-content-details-metadata-item'>
                          <span className='ems-item-view-content-details-metadata-item-title'>{item.name}</span>
                          <span className='ems-item-view-content-details-metadata-item-value'>
                            { 
                              item.metadata === 'audioLength' || 
                              item.metadata === 'videoLength' ? 
                              msToTime(this.state.asset.metadata[item.metadata]) :
                              this.renderMetadataValue(this.state.asset.metadata[item.metadata])
                            }
                          </span>
                      </div> : false)}
                </div>}
              </div>
            </div>}
            {this.state.assetList.length > 0 && <AssetList
                token={this.URLSearchParams.token}
                hideSorting={true}
                assetList={this.state.assetList}
                basketList={this.state.basketList}
                toggleBasket={id => this.toggleBasket(id)}
                assetHeadline={this.state.asset.metadata.assetType === 'collection' && 
                  <h3 className='ems-title ems-asset-list-title'>
                    {this.state.asset.metadata.name} 
                    <button className='ems-collection-basket-button ems-button' onClick={e => this.toggleBasket(this.state.asset.id)}>{!addedToBasket ? 'Add collection to' : 'Remove collection from'} basket</button>
                  </h3>}
              />}
          </div>}
          </div>
        </div>
        <Footer settings={this.state.generalSiteSettings}/>
      </div>
    );
  }
}

export default ShareItemView;
